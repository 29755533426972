import { AuthConfig } from 'angular-oauth2-oidc';
export const FsxB2CDocumentDiscoveryURL = 'https://fsagencyilsosdev.b2clogin.com/fsagencyilsosdev.onmicrosoft.com/B2C_1A_DEMO_EMAIL_SIGNIN/v2.0/.well-known/openid-configuration';
const FsxB2CClientId = '13ee4276-b4ce-420f-83b6-5bbbc030c5fd';
const FsxB2CScopes = 'openid profile email offline_access https://fsagencyilsosdev.onmicrosoft.com/13ee4276-b4ce-420f-83b6-5bbbc030c5fd/access_as_user';
const FsxB2CIssuer = 'https://fsagencyilsosdev.b2clogin.com/245f1366-b317-4450-adef-8b892ab9bf9b/v2.0/'
const FsxB2CLoginUrl = 'https://fsagencyilsosdev.b2clogin.com/fsagencyilsosdev.onmicrosoft.com/B2C_1A_DEMO_EMAIL_SIGNIN/oauth2/v2.0/authorize';
const FsxB2CLogoutUrl = 'https://fsagencyilsosdev.b2clogin.com/fsagencyilsosdev.onmicrosoft.com/B2C_1A_DEMO_EMAIL_SIGNIN/oauth2/v2.0/logout'

export const authConfig: AuthConfig = {
  // ToDo: Need to store these magic strings in environment variables.
  issuer: FsxB2CIssuer,
  loginUrl: FsxB2CLoginUrl,
  logoutUrl: FsxB2CLogoutUrl,
  // ToDo: I believe we need to register all possible returnUri's in the Azure AD B2C App Registration
  redirectUri: window.location.origin + '/index.html',
  clientId: FsxB2CClientId,
  responseType: 'code',
  strictDiscoveryDocumentValidation: false,
  scope: FsxB2CScopes,
  showDebugInformation: false,
  skipIssuerCheck: false,
  // timeoutFactor: .01
}
