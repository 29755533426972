import { Routes } from '@angular/router';
import {azureB2CGuard} from './app/core/guards/azure-b2c/azure-b2c-guard';
import {messageGuard} from './app/core/guards/message/message.guard';
import {redirectGuard} from './app/core/guards/redirect/redirect.guard';
import { APP_TITLE } from './constants';

export enum ROUTES {
  REGISTER = 'register',
  PASSWORD = 'password',
  APP = 'app',
  MESSAGE = 'message',
  DOCUMENTS = 'documents',
  PUBLIC_DOCKET_SEARCH = 'docket-search'
}

export enum ROUTER_OUTLETS {
  REGISTER = 'registeroutlet',
  PASSWORD = 'passwordoutlet',
  SIDEPANEL_USERS = 'sidepanel-users',
  SIDEPANEL_HOME = 'sidepanel-home',
  SIDEPANEL_FILINGS = 'sidepanel-filings',
}

export const APP_ROUTES: Routes = [
  {
    path: ROUTES.DOCUMENTS,
    title: 'Documents',
    loadComponent: () => import('./app/pages/documents/documents.component').then(m => m.DocumentsComponent),
    loadChildren: () => import('./app/pages/documents/documents.routes'),
  },
  {
    path: ROUTES.REGISTER,
    title: 'Registration',
    loadComponent: () => import('./app/pages/register/register.component').then(m => m.RegisterComponent),
    loadChildren: () => import('./app/pages/register/register.routes'),
  },
  {
    path: ROUTES.PASSWORD,
    title: 'Password',
    loadComponent: () => import('./app/pages/password/password.component').then(m => m.PasswordComponent),
    loadChildren: () => import('./app/pages/password/password.routes'),
  },
  {
    path: ROUTES.MESSAGE,
    title: 'Message',
    loadComponent: () => import('./app/pages/mesage/message.component').then(m => m.MessageComponent),
    canActivate: [
      messageGuard
    ]
  },
  {
    path: ROUTES.APP,
    title: APP_TITLE,
    canActivate: [
      redirectGuard,
      azureB2CGuard
    ],
    loadComponent: () => import('./app/pages/main/main.component').then(m => m.MainComponent),
    loadChildren: () => import('./app/pages/main/main.routes'),
  },
  { path: '', redirectTo: ROUTES.APP, pathMatch: 'full'},
  { path: '**', redirectTo: ROUTES.APP, pathMatch: 'full' },
]
