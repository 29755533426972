/**
 * The application title used as in all route titles.
 */
export const APP_TITLE = 'IL SOS';

/**
 * The maximum number of counties the user is allowed to add 
 * in the "Case Information" section.
 */
export const MAX_COUNTIES = 1;

/*
 * Case Type constants to avoid using magic strings in components.
 * The ids from the server should stay the same 
 * (safer to use these rather than labels which may change)
 */
export enum CaseType {
    General = 'a0454696-0660-4951-b5a1-12f021c6a474',
    CrimeVictim = '89e9c662-54d9-4685-bbe0-c299e46ffba7'
} 
