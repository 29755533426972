import { AuthService } from './auth.service'
import {inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, switchMap, take} from 'rxjs';
import {ROUTES} from '../../../routes';

export function authAppInitializerFactory(authService: AuthService): () => Observable<void> {
  const activatedRoute =  inject(ActivatedRoute);
  return () => activatedRoute.url
    .pipe(
      take(1),
      switchMap(url => {
        // We want to make sure that we DO run the initial login sequence if we're in the app
        if (window.location.pathname.includes(ROUTES.APP)) {
          return authService.runInitialLoginSequence();
        }
        // Don't run the initial login sequence if we're on the register or documents page
        else {
          if (!window.location.pathname.includes(ROUTES.REGISTER) && !window.location.pathname.includes(ROUTES.DOCUMENTS)) {
            return authService.runInitialLoginSequence();
          }
        }
        return Promise.resolve();
      }),
    )
  ;
}
